import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Employeeshifts,UnitMaster,ProductMaster,Employeedetails,PackMaster } from 'src/app/model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class EmployeeshiftsService {

    public pageName = 'Employee Assign Shifts';
    public dataList = new Subject<Array<Employeeshifts>>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.EmployeeshiftsApi[apiOption];
    }

    public getUnits() {
        const url = this.config.ipAddress + this.config.unitMasterApi[ApiActions.Get];
        const data = new Subject<Array<UnitMaster>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.unitMasterList;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }

    public getEmployees() {
        const url = this.config.ipAddress + this.config.EmployeeDetailsApi[ApiActions.Get];
        const data = new Subject<Array<Employeedetails>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Employeedetails;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }

    public getUnitShiftsData(unit_id:number) {
        const url = this.config.ipAddress + this.config.EmployeeshiftsApi[ApiActions.GetSingle];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append('unit_id', unit_id + '');
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.ShiftTimings;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }

    public getDataRecords() {
        let resultList = new Array<Employeeshifts>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.EmployeeshiftsList) {
                    resultList = response.result.EmployeeshiftsList.map(e => {
                      
                            e.FromDate = new Date(e.FromDate);
                            e.ToDate = new Date(e.ToDate);
                        
                        return e;
                    });
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public deleteUnitShiftsData(empsht_id:number) {
        const url = this.config.ipAddress + this.config.EmployeeshiftsApi[ApiActions.Delete];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append('empsht_id', empsht_id + '');
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                  
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public updateData(objData: Employeeshifts) {
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Employeeshifts) {
        objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Employeeshifts) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();
console.log(objData);
        fData.append('empsht_id', objData.empsht_id + '');
        fData.append('emp_id', objData.emp_id + '');

        const items = objData.items.map(e => {
            return {
                empsht_id: e.empsht_id,
                unit_id: e.unit_id,
                shift_id: e.shift_id,
                FromDate:  this.util.getDate(e.FromDate),
                ToDate:  this.util.getDate(e.ToDate),

            };
        });

        fData.append('items', JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}

