import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject,Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';
import {
  ItemMasterInventory
  , ItemCategory
  , ItemGrade
  , ItemClass
  , ItemForm
  , ProductUOM
} from 'src/app/model';

@Injectable({
  providedIn: 'root'
})
export class ItemMasterInventoryService {

  public pageName = 'Raw Material Inventory';
  public dataList = new Subject<Array<ItemMasterInventory>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.iteminventoryserviceApi[apiOption];
  }
  public getCountryList(): Subject<Array<any>> {
    const url = this.config.ipAddress + this.config.currencyserviceApi[ApiActions.Get];
    const countrylist: Array<any> = [];
    const result = new Subject<Array<any>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Currency.map(element => {
           // element.status = Number(element.recStatus) === 1 ? true : false;
            countrylist.push(element);
          });
          result.next(countrylist);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return result;
  }
  public getCategories(): Subject<Array<ItemCategory>> {
    const url = this.config.ipAddress + this.config.itemCategoryApi[ApiActions.Get];
    const dataArray: Array<ItemCategory> = [];
    const data = new Subject<Array<ItemCategory>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.CategoryList.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getGrades() {
    const url = this.config.ipAddress + this.config.itemGradeApi[ApiActions.Get];
    const dataArray: Array<ItemGrade> = [];
    const data = new Subject<Array<ItemGrade>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.GradeList.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getClasses() {
    const url = this.config.ipAddress + this.config.itemClassApi[ApiActions.Get];
    const dataArray: Array<ItemClass> = [];
    const data = new Subject<Array<ItemClass>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.ClassList.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getForms() {
    const url = this.config.ipAddress + this.config.itemFormApi[ApiActions.Get];
    const dataArray: Array<ItemForm> = [];
    const data = new Subject<Array<ItemForm>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.FormList.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }
  public getUOMCodes() {
    const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
    const dataArray: Array<ProductUOM> = [];
    const data = new BehaviorSubject<Array<ProductUOM>>([]);

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.UnitsOfMeasurement.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }
  public getDataRecords() {
    const dataArray: Array<ItemMasterInventory> = [];
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.ItemMasterList) {
          response.result.ItemMasterList.map(element => {
            dataArray.push(element);
          });
        }
        this.dataList.next(dataArray);
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

 

  public saveDraft(itemid,stkqty,uomId,itmunit_price,itm_currency) {
    return this.Save(this.getUrl(ApiActions.Update), itemid,stkqty,uomId,itmunit_price,itm_currency);
  }

  private Save(action: string, itemid,stkqty,uomId,itmunit_price,itm_currency) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('itemId', itemid);
    fData.append('uomId', uomId);
    fData.append('stkqty', stkqty);
    fData.append('itmunit_price', itmunit_price);
    fData.append('itm_currency', itm_currency);

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
}
