import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import {
  ProductUOM,
  CustomerOrder,
  ProductMaster,
  Customer,
  PackMaster
} from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CustomerOrderService {

  public pageName = 'Customer Order';
  public dataList = new Subject<Array<CustomerOrder>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.customerOrderApi[apiOption];
  }

  public getUOMCodes() {
    const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
    const data = new Subject<Array<ProductUOM>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.UnitsOfMeasurement;
          data.next(result);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getProductsData() {
    const resultList = new Subject<Array<ProductMaster>>();
    const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Customerorderproducts];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
          const list = response.result.productMasterList;
          resultList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return resultList;
  }

  public getCustomers() {
    const url = this.config.ipAddress + this.config.customerMasterApi[ApiActions.Get];
    const data = new Subject<Array<Customer>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.Customermaster;
          data.next(result);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getPackMasterData() {
    const url = this.config.ipAddress + this.config.packMasterApi[ApiActions.Get];
    const data = new Subject<Array<PackMaster>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.Packmaster;
          data.next(result);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getDataRecords() {
    let resultList = new Array<CustomerOrder>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.CustomerOrders) {
          resultList = response.result.CustomerOrders.map(e => {
            e.customerPurchaseDate = new Date(e.customerPurchaseDate);
            e.items = e.items.map(i => {
              i.deliveryDate = new Date(i.deliveryDate);
              return i;
            });
            return e;
          });
          this.dataList.next(resultList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public updateData(objData: CustomerOrder) {
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: CustomerOrder) {
    objData.status = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: CustomerOrder) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();

    fData.append('customerOrderId', objData.customerOrderId  + '');
    fData.append('customerId', objData.customerId  + '');
    fData.append('orderNumber', objData.customerPurchaseOrder + '');
    fData.append('orderDate', this.util.getDate(objData.customerPurchaseDate));
    fData.append('status', objData.status + '');

    const items = objData.items.map(e => {
      return {
        prodId: e.prodId,
        prodSpecs: e.prodSpecs,
        orderQty: e.orderQty,
        orderUom: e.orderUom,
        deliveryDate: this.util.getDate(e.deliveryDate),
        deliveryLocation: e.deliveryLocation,
        orderPacking: e.orderPacking,
        prodOrdPrice: e.prodOrdPrice,
        hsnCode: e.hsnCode,
        productOrderGst: e.gst,
        dispatchInst: e.dispatchInst
      };
    });

    fData.append('items', JSON.stringify(items));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
}
