import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  [x: string]: any;
  // Whether or not to enable debug mode
  public enableDebug = true;
  // Application version.
  public version = '';
  /** Holds the title of the Application. */
  public title = '';
  /** Holds the Owner of the Product for copyright. */
  public productOwner = '';
  /** Busy cursor hideDelay time in millisecond */
  public hideDelay = 0;
  /** Holds Ip Address of the server. */
  public ipAddress = '';

  /** Holds the login api calls list. */
  public logInApi = {};
  /** Holds the left menu api calls list. */
  public leftMenuApi = {};
  /** Holds the User api calls list. */
  public userApi = {};
 public paddingAtStart= true;
   public interfaceWithRoute= true;
   public collapseOnSelect= true;
   public highlightOnSelect= true;

   public classname = 'my-custom-class';
   public listBackgroundColor = '#1799D1';
   public fontColor = '#fff';
  public  backgroundColor = '#0F5E98';
  public  selectedListFontColor = 'red';
  public  useDividers = true;
   public rtlLayout = false;

}
