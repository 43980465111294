import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { Dataimport } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class DataimportService {

  public pageName = 'Data Import';
  public dataList = new Subject<Array<Dataimport>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
    private msgSvc: MsgService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.DataimportApi[apiOption];
  }

  public getTableList(): Subject<Array<any>> {
    const url = this.config.ipAddress + this.config.DataimportApi[ApiActions.Get];
    const dataArray: Array<any> = [];
    const data = new Subject<Array<any>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.tables.map(element => {
            //  element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }
  public getTableColumnList(tablename: string) {
    const resultList = new Subject<any>();
    const url = this.config.ipAddress + this.config.DataimportApi[ApiActions.GetSingle];
    const fData = new FormData();
    fData.append('tablename', tablename + '');
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.columns) {
          const list = response.result.columns;
          resultList.next(list);
        }
      },
        err => {
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
        });
    return resultList;
  }
  public getDataRecords() {

    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<Dataimport> = [];
          response.result.Dataimport.map(element => {
            element.status = element.rec_status + '' === '1' ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public addData(objData: any, col: any, selectedTable: any) {
    const fData = new FormData();
    fData.append('exceldata', JSON.stringify(objData) + '');
    fData.append('headermaping', JSON.stringify(col) + '');
    fData.append('selectedTable', selectedTable + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }
  public prodspecclone(fromspec: any, tospec: any) {
    const fData = new FormData();
    fData.append('fromspec', fromspec + '');
    fData.append('tospec', tospec + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }
  public updateData(objData: Dataimport) {
    const fData = new FormData();
    fData.append('DataimportId', objData.country_id + '');
    fData.append('DataimportCode', objData.country_code + '');
    fData.append('DataimportDesc', objData.country_name + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('DataimportId', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: Dataimport) {
    const fData = new FormData();
    fData.append('stat', objData.status ? '1' : '0');
    fData.append('DataimportId', objData.country_id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }
}
