import { Injectable } from '@angular/core';
import { ItemSpecification, ProductParameter } from 'src/app/model';
import { Subject } from 'rxjs';
import { ApiActions, UtilService } from '../common/util.service';
import {
  ItemMaster
  , ProductUOM
} from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ItemSpecificationService {
  public pageName = 'Item Specification';
  public dataList = new Subject<Array<ItemSpecification>>();
  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.itemSpecificationApi[apiOption];
  }

  public getParameters() {
    const dataArray: Array<ProductParameter> = [];
    const data = new Subject<Array<ProductParameter>>();
    const url = this.config.ipAddress + this.config.productParameterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Productparameter.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getUOMCodes() {
    const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
    const dataArray: Array<ProductUOM> = [];
    const data = new Subject<Array<ProductUOM>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.UnitsOfMeasurement.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getItemMasterRecords() {
    const itemMasterList = new Subject<Array<ItemMaster>>();
    const url = this.config.ipAddress + this.config.itemMasterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.ItemMasterList) {
          const list = response.result.ItemMasterList;
          itemMasterList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return itemMasterList;
  }

  public getItemSpecRecords() {
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          this.dataList.next(response.result.itemSpecList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public updateData(objData: ItemSpecification) {
    objData.specStatus = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: ItemSpecification) {
    objData.specStatus = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: ItemSpecification) {
    //console.log(objData);
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('item_id', objData.itemId + '');
    fData.append('itemspec_id', objData.itemspec_id + '');
    fData.append('specLink', objData.specLink);
    fData.append('itemspec_number', objData.ItemSpecNumber);
    fData.append('msdsLink', objData.msdsLink);
    fData.append('TestMethodDoc', objData.TestMethodDoc);
   
    const items = objData.items.map(e => {
      //console.log(e.testMethod);
      return {
        paramId: e.paramId,
        paramValue: e.paramValue,
        paramUOMId: e.paramUOMId,
        testMethod: e.testMethod,
        specsRemarks: e.specsRemarks,

      };
  });
//console.log(JSON.stringify(items));
  fData.append('items', JSON.stringify(items));
    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

}
