import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Attendance } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  public pageName = 'Attendance';
  public dataList = new Subject<Array<Attendance>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.AttendanceApi[apiOption];
  }
  public getDataRecords() {
    let resultList = new Array<Attendance>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
    .subscribe((response: any) => {
      console.log(response);
        if (response && response.result && response.result.AttendanceList) {
            resultList = response.result.AttendanceList;
            this.dataList.next(resultList);
        }
    },
        err => {
            //this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
        });


  }

  public saveDraft(objData:Attendance) {
    return this.Save(this.getUrl(ApiActions.Create), objData);

  }
  public updateData(objData:Attendance) {
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  private Save(action: string, objData: Attendance) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    // fData.append('paycomp_id', objData.paycomp_id + '');
    // fData.append('paycomp_code', objData.paycomp_code);
    // fData.append('paycomp_name', objData.paycomp_name);

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
        if (response) {
            this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
            responseMsg.next('');
        }
    },
        err => {
            responseMsg.next(err);
        });

    return responseMsg;
}
}
