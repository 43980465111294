import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Assignbatchestochemist,Batch,Chemist } from 'src/app/model';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class AssignbatchestochemistService {

    public pageName = 'Assign Batches to Chemist';
    public dataList = new Subject<Array<Assignbatchestochemist>>();
    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private common: CommonDataService,
        private msgSvc: MsgService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.AssignbatchestochemistApi[apiOption];
    }
    public getDataRecords() {
        const dataArray: Array<Assignbatchestochemist> = [];
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.AssignbatchestochemistList) {
                    response.result.AssignbatchestochemistList.map(element => {
                        dataArray.push(element);
                    });
                }
                this.dataList.next(dataArray);
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public getChimestList(): BehaviorSubject<Array<Chemist>> {
        const url = this.config.ipAddress + this.config.AssignbatchestochemistApi[ApiActions.Delete];
        const dataArray: Array<Chemist> = [];
        const data = new BehaviorSubject<Array<Chemist>>([]);
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.Chemist.map(element => {
                        element.status = element.status == '1' ? true : false;
                        dataArray.push(element);
                    });
                    data.next(dataArray);
                }
            },
                err => {
                    this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }

    public getPlanBatchestList() {
        const url = this.config.ipAddress + this.config.AssignbatchestochemistApi[ApiActions.UpdateStatus];
        const dataArray: Array<Batch> = [];
        const data = new BehaviorSubject<Array<Batch>>([]);

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.Batches.map(element => {
                        element.status = element.status == '1' ? true : false;
                        dataArray.push(element);
                    });
                    data.next(dataArray);
                }
            },
                err => {
                    this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getUserPlanBatchestList(user_id:number,plan_id:number) {
        const url = this.config.ipAddress + this.config.AssignbatchestochemistApi[ApiActions.GetSingle];
        const dataArray: Array<Batch> = [];
        const data = new BehaviorSubject<Array<Batch>>([]);
        const fData = new FormData();
        fData.append('user_id', user_id+'');
        fData.append('plan_id', plan_id+'');
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.AssignbatchestochemistList.map(element => {
                        element.status = element.status == '1' ? true : false;
                        dataArray.push(element);
                    });
                    data.next(dataArray);
                }
            },
                err => {
                    this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public updateData(objData: Assignbatchestochemist) {
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Assignbatchestochemist) {
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Assignbatchestochemist) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();
        fData.append('batch_to_chmstid', objData.batch_to_chmstid + '');
        fData.append('user_id', objData.user_id + '');
        fData.append('batch_num', objData.batch_num);

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}

