import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';
import {
  ItemMaster
  , ItemCategory
  , ItemGrade
  , ItemClass
  , ItemForm
} from 'src/app/model';

@Injectable({
  providedIn: 'root'
})
export class ItemMasterService {

  public pageName = 'Item Master';
  public dataList = new Subject<Array<ItemMaster>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.itemMasterApi[apiOption];
  }

  public getCategories(): Subject<Array<ItemCategory>> {
    const url = this.config.ipAddress + this.config.itemCategoryApi[ApiActions.Get];
    const dataArray: Array<ItemCategory> = [];
    const data = new Subject<Array<ItemCategory>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.CategoryList.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getGrades() {
    const url = this.config.ipAddress + this.config.itemGradeApi[ApiActions.Get];
    const dataArray: Array<ItemGrade> = [];
    const data = new Subject<Array<ItemGrade>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.GradeList.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getClasses() {
    const url = this.config.ipAddress + this.config.itemClassApi[ApiActions.Get];
    const dataArray: Array<ItemClass> = [];
    const data = new Subject<Array<ItemClass>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.ClassList.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getForms() {
    const url = this.config.ipAddress + this.config.itemFormApi[ApiActions.Get];
    const dataArray: Array<ItemForm> = [];
    const data = new Subject<Array<ItemForm>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.FormList.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getDataRecords() {
    const dataArray: Array<ItemMaster> = [];
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.ItemMasterList) {
          response.result.ItemMasterList.map(element => {
            dataArray.push(element);
          });
        }
        this.dataList.next(dataArray);
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }
  public getTableDataRecords() {
    const dataArray: Array<ItemMaster> = [];
    this.http.post(this.getUrl(ApiActions.Delete), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.ItemMasterList) {
          response.result.ItemMasterList.map(element => {
            dataArray.push(element);
          });
        }
        this.dataList.next(dataArray);
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }
  public updateData(objData: ItemMaster) {
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: ItemMaster) {
    objData.status = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: ItemMaster) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('status', objData.status + '');
    fData.append('itemId', objData.itemId + '');
    fData.append('itemCode', objData.itemCode);
    fData.append('itemDesc', objData.itemDesc);
    fData.append('itemCategoryId', objData.itemCategoryId + '');
    fData.append('itemGradeId', objData.itemGradeId + '');
    fData.append('itemClassId', objData.itemClassId + '');
    fData.append('itemFormId', objData.itemFormId + '');
    fData.append('hsnCode', objData.hsnCode);
    fData.append('specLink', objData.specLink);
    fData.append('msdsLink', objData.msdsLink);

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
}
