import {
    Injectable
} from '@angular/core';
import {
    ApiActions,
    UtilService
} from '../common/util.service';
import {BehaviorSubject,
    Subject
} from 'rxjs';
import {
    Plancomparison,
	Plancomparisondata,
    DataConfirmNProject
} from 'src/app/model';
import {
    HttpClient
} from '@angular/common/http';
import {
    AppConfigService
} from '../common/app-config.service';
import {
    MsgService
} from '../common/msg.service';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class PlancomparisonService {

    public pageName = 'Plans';
    public dataList = new Subject < Array < any >> ();
    public projectedDataList = new Subject < Array < any >> ();
	//public dataList = new Subject < Array < any >> ();
	public dataListtwo = new Subject < Array < any >> ();
	public detailsdata = new Subject < Array < any >> ();
    public summarydata = new Subject < Array < any >> ();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router:Router
    ) {}

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.plancomparisonApi[apiOption];
    }

    public getProjectedRecords(fromDate: Date, toDate: Date) {
        let resultList = new Array < Plancomparison > ();
        const url = this.config.ipAddress + this.config.plancomparisonApi[ApiActions.Get];
        const fData = new FormData();
        fData.append('fromDate', this.util.getDate(fromDate));
        fData.append('toDate', this.util.getDate(toDate));

        this.http.post(url, fData, {
                headers: undefined
            })
            .subscribe((response: any) => {
                    if (response && response.result && response.result.InternalSalesOrderList) {
                        resultList = response.result.InternalSalesOrderList.map((e, i) => {
                            e.s_no = i + 1;
                            return e;
                        });
                        this.projectedDataList.next(resultList);
                    }
                },
                err => {
                      this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public getPlanDetails(plan_id:Number) {
        let resultList = new Array < Plancomparison > ();
        const url = this.config.ipAddress + this.config.plancomparisonApi[ApiActions.Delete];
        const fData = new FormData();
        fData.append('plan_id', plan_id+'');

        this.http.post(url, fData, {
                headers: undefined
            })
            .subscribe((response: any) => {
                    if (response && response.result && response.result.InternalSalesOrderList) {
                        resultList = response.result.InternalSalesOrderList.map((e, i) => {
                            e.s_no = i + 1;
                            return e;
                        });
                        this.detailsdata.next(resultList);
                    }
                },
                err => {
                      this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
                });
    }  

	public getCOmpareRecords(fromDate: Date, toDate: Date) {
        let resultListone = new Array < Plancomparisondata > (); 
		let resultListtwo = new Array < Plancomparisondata > ();
        let resultsummary = new Array <any> ();
        const url = this.config.ipAddress + this.config.plancomparisonApi[ApiActions.DraftSave];
        const fData = new FormData();
        fData.append('fromDate', this.util.getDate(fromDate));
        fData.append('toDate', this.util.getDate(toDate));

        this.http.post(url, fData, {
                headers: undefined
            })
            .subscribe((response: any) => {
                    if (response && response.result && response.result.CompareOneList) {
                        resultListone = response.result.CompareOneList.map((e, i) => {
                            e.s_no = i + 1;
                            return e;
                        });
                        this.dataList.next(resultListone);
                    }
					
                },
                err => {
                      this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public getCategories(fromDate: Date, toDate: Date): BehaviorSubject<Array<Plancomparison>> {
		const url = this.config.ipAddress + this.config.plancomparisonApi[ApiActions.getTable];
		const dataArray: Array<Plancomparison> = [];
		const data = new BehaviorSubject<Array<Plancomparison>>([]);
		const fData = new FormData();
        fData.append('fromDate', this.util.getDate(fromDate));
        fData.append('toDate', this.util.getDate(toDate));
		this.http.post(url, fData, { headers: undefined })
		  .subscribe((response: any) => {
			if (response && response.result) {
			  response.result.InternalSalesOrderList.map(element => {
				element.status = element.status == '1' ? true : false;
				dataArray.push(element);
			  });
			  data.next(dataArray);
			}
		  },
			err => {
			    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
			});
		return data;
	}
    public getsaleforcastplans(fromDate: Date, toDate: Date): BehaviorSubject<Array<Plancomparison>> {
		const url = this.config.ipAddress + this.config.plancomparisonApi[ApiActions.GetSingle];
		const dataArray: Array<Plancomparison> = [];
		const data = new BehaviorSubject<Array<Plancomparison>>([]);
		const fData = new FormData();
        fData.append('fromDate', this.util.getDate(fromDate));
        fData.append('toDate', this.util.getDate(toDate));
		this.http.post(url, fData, { headers: undefined })
		  .subscribe((response: any) => {
			if (response && response.result) {
			  response.result.InternalSalesOrderList.map(element => {
				element.status = element.status == '1' ? true : false;
				dataArray.push(element);
			  });
			  data.next(dataArray);
			}
		  },
			err => {
			    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
			});
		return data;
	}
    
    public updateData(objData: Plancomparison) {
       // objData.status = this.util.getSaveStatus();
        return this.Save({
            projectedOrd: this.config.ipAddress + this.config.plancomparisonApi[ApiActions.Update]
        }, objData);
    }



    private Save(api: {
        projectedOrd: string
    }, objData: Plancomparison) {
        const responseMsg = new Subject < string > ();
        let fData = new FormData();
                
        fData.append('data', JSON.stringify(objData));
        this.http.post(api.projectedOrd, fData, {
            headers: undefined
        }).subscribe((response: any) => {
                if (response) {
                    this.msgService.addSuccessMsg({
                        summary: this.pageName,
                        detail: response.result.status
                    });
                    responseMsg.next('');
                }
            },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }


    private generatenewplanidsave(api: {
        projectedOrd: string
    }, fromDate, toDate) {

        const responseMsg = new Subject < string > ();
        let fData = new FormData();
        fData.append('fromDate', this.util.getDate(fromDate));
        fData.append('toDate', this.util.getDate(toDate));
        this.http.post(api.projectedOrd, fData, {
            headers: undefined
        }).subscribe((response: any) => {
                if (response) {
                    this.msgService.addSuccessMsg({
                        summary: this.pageName,
                        detail: response.result.status
                    });
                    responseMsg.next('');
                }
            },
            err => {
                responseMsg.next(err);
            });
        return responseMsg;
    }
	
	public updateStatus(fromDate: Date, toDate: Date,objData:Plancomparison) {
		const responseMsg = new Subject<string>();
		const status = (objData.status) ? 1 : 0;
		const plan_id = objData.plan_id;
		const plan_remark = objData.plan_remark;
		
		const fData = new FormData();
        fData.append('fromDate', this.util.getDate(fromDate));
        fData.append('toDate', this.util.getDate(toDate));
		fData.append('plan_id',plan_id +'');
		fData.append('plan_remark',plan_remark);
		fData.append('status',status +'');

		this.http.post(this.getUrl(ApiActions.UpdateStatus),fData, { headers: undefined }).subscribe((response: any) => {
			if (response) {
				this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.status.statusMessage });
				responseMsg.next(response.status.statusMessage);
			} else {
				this.msgService.addErrorMsg({ summary: this.pageName, detail: response.Error });
			}
		},
		err => {
			responseMsg.next(err);
		});
		return responseMsg;
	}
}