<!-- Footer Start -->
<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        2023 &copy; Rapicon APS. All Rights Reserved.
      </div>
    </div>
  </div>
</footer>
<!-- end Footer -->