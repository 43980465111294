import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Productinventoryquarantine, ProductUOM, ProductMaster, Customer } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class ProductinventoryquarantineService {


    public pageName = 'Product Quarantine List';
    public dataList = new Subject<Array<Productinventoryquarantine>>();
    public OrderdataList = new Subject<Array<Productinventoryquarantine>>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.ProductinventoryquarantineApi[apiOption];
    }

    public getUOMCodes() {
        const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
        const data = new Subject<Array<ProductUOM>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.UnitsOfMeasurement;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }

    public getProductsData() {
        const resultList = new Subject<Array<ProductMaster>>();
        const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Customerorderproducts];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.productMasterList) {
                    const list = response.result.productMasterList;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }

    public getCustomers() {
        const url = this.config.ipAddress + this.config.SupplierMasterApi[ApiActions.Get];
        const data = new Subject<Array<Customer>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Supplierlistmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getCUrrancyList(): Subject<Array<any>> {
        const url = this.config.ipAddress + this.config.currencyserviceApi[ApiActions.Get];
        const countrylist: Array<any> = [];
        const result = new Subject<Array<any>>();
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.Currency.map(element => {
                        // element.status = Number(element.recStatus) === 1 ? true : false;
                        countrylist.push(element);
                    });
                    result.next(countrylist);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return result;
    }
    public getEnquiryTypedropdownData(fromdate:Date,todate:Date) {
        const url = this.config.ipAddress + this.config.ProductinventoryquarantineApi[ApiActions.Create];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("fromdate", this.util.getDate(fromdate));
        fData.append("todate", this.util.getDate(todate));
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getEnquiryTypedropdownDataSupplier(cust_id:number) {
        console.log(cust_id);
        const url = this.config.ipAddress + this.config.ProductinventoryquarantineApi[ApiActions.GetSingle];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("cust_id", cust_id+'');
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getRecivablecodedropdownData(OrderId:number) {
        const url = this.config.ipAddress + this.config.ProductinventoryquarantineApi[ApiActions.UpdateStatus];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("order_id",OrderId +"");
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getDataRecords() {
        let resultList = new Array<Productinventoryquarantine>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.ProductquarantineList) {
                    resultList = response.result.ProductquarantineList.map(e => {

                        e.QuotDate = new Date(e.QuotDate);
                        e.OrdDate = new Date(e.OrdDate);
                        e.IssuedDate = new Date(e.IssuedDate);
                        e.BatchDate = new Date(e.BatchDate);

                        return e;
                    });
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public getOrderDataRecords(orderId:number) {
        let resultList = new Array<Productinventoryquarantine>();
        const fData = new FormData();

        fData.append("order_id",orderId +"");
        this.http.post(this.getUrl(ApiActions.Delete), fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.ProductquarantineList) {
                    resultList = response.result.ProductquarantineList.map(e => {

                        e.QuotDate = new Date(e.QuotDate);
                        e.OrdDate = new Date(e.OrdDate);
                        e.IssuedDate = new Date(e.IssuedDate);
                        e.BatchDate = new Date(e.BatchDate);

                        return e;
                    });
                    this.OrderdataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public updateData(objData: Productinventoryquarantine) {
        console.log(objData);
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Productinventoryquarantine) {
        objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Productinventoryquarantine) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append("saleord_id", objData.saleord_id + "");
        fData.append("transfer_id", objData.transfer_id + "");
        fData.append("cust_id", objData.cust_id + "");
        fData.append('BatchNum', objData.BatchNum + '');
        fData.append('ReceiveDate', this.util.getDate(objData.BatchDate));

        const items = objData.items.map((e) => {
            return {
                quarandtl_id: e.quarandtl_id,
                prod_id: e.prod_id,
                ProdSpecs: e.ProdSpecs,
                orderUom: e.uom_id,
                TransferQty: e.TransferQty,
                QuotPrice: e.QuotPrice,
                currency_id: e.currency_id,
                transfer_uom: e.ord_uom,
                qc_yn: e.QCYesNo,
            };
        });

        fData.append('items', JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}

