<!-- Topbar Start -->
<div class="navbar navbar-expand flex-column flex-md-row navbar-custom">
  <div class="container-fluid">
    <!-- LOGO -->
    <a routerLink="/" class="navbar-brand mr-0 mr-md-2 logo">
      <span class="logo-lg">
        <img src="assets/images/logo.svg" alt="" height="34" />
      </span>
      <span class="logo-sm">
        <img src="assets/images/sm.png" alt="" height="24">
      </span>
    </a>

    <ul class="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
      <li class="">
        <button class="button-menu-mobile open-left" (click)="toggleMobileMenu($event)">
          <i [attr.data-feather]="'menu'" class="menu-icon" appFeatherIcon></i>
          <i [attr.data-feather]="'x'" class="close-icon" appFeatherIcon></i>
        </button>
      </li>
    </ul>

    <ul class="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0">
      <li class="d-none d-sm-block">
        <div class="app-search">
          <form  (ngSubmit) = "onSubmit(null)" >
            <div class="input-group">
              <input type="text" class="form-control" name="searchkeyword" [(ngModel)]="searchkeyword" placeholder="Search...">
              <i [attr.data-feather]="'search'" appFeatherIcon></i>
            </div>
          </form>
        </div>
      </li>
      <li class="dropdown notification-list" placement="left" ngbTooltip="{{ MessagesCount }}  new Messages" ngbDropdown>
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript: void(0);" role="button">
          <i [attr.data-feather]="'message-circle'" appFeatherIcon></i>
          <span *ngIf="MessagesCount>0 " class="noti-icon-badge"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right dropdown-lg" ngbDropdownMenu>
          <div class="slimscroll noti-scroll" appSlimScroll>

            <!-- items-->
            <a href="javascript:void(0);" *ngFor="let item of headermessagesItems"
              class="dropdown-item notify-item border-bottom" ngbDropdownItem>
              <div class="notify-icon bg-{{ item.color }}" *ngIf="item.icon"><i class="{{ item.icon }}"></i></div>
              <div class="notify-icon">
                <img src="assets/images/users/avatar-7.jpg" class="img-fluid rounded-circle" alt="" />
              </div>
              <p class="notify-details">{{ item.user_fname }} {{ item.user_lname }}<small class="text-muted">{{ item.msg_desc }}</small>
              </p>
            </a>

          </div>

          <!-- All-->
          <a  routerLink="/pages-profile"
            class="dropdown-item text-center text-primary notify-item notify-all border-top">
            View all
            <i class="fi-arrow-right"></i>
          </a>

        </div>
      </li>
      

      <li class="dropdown notification-list" placement="left" ngbTooltip=" {{ notificationCount }}  new notifications" ngbDropdown>
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript: void(0);" role="button">
          <i [attr.data-feather]="'bell'" appFeatherIcon></i>
          <span  *ngIf="notificationCount>0 " class="noti-icon-badge"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right dropdown-lg" ngbDropdownMenu>

          <!-- item-->
          <div class="dropdown-item noti-title border-bottom" ngbDropdownItem>
            <h5 class="m-0 font-size-16">
              <span class="float-right">
              </span>Notification
            </h5>
          </div>

          <div class="slimscroll noti-scroll" appSlimScroll>

            <!-- items-->
            <a href="javascript:void(0);" *ngFor="let item of notificationItems"
              class="dropdown-item notify-item border-bottom" ngbDropdownItem>
              <div class="notify-icon bg-{{ item.color }}" *ngIf="item.icon"><i class="{{ item.icon }}"></i></div>
              
              <p class="notify-details"><small class="text-muted">{{ item.ntfDesc }}</small>
              </p>
            </a>

          </div>

          <!-- All-->
          <a  routerLink="/pages-profile"
            class="dropdown-item text-center text-primary notify-item notify-all border-top">
            View all
            <i class="fi-arrow-right"></i>
          </a>

        </div>
      </li>

      <li class="dropdown notification-list" placement="left" ngbTooltip="User Activity">
        <a href="javascript:void(0);" class="nav-link right-bar-toggle" (click)="toggleRightSidebar()">
          <i [attr.data-feather]="'activity'" class="right-bar-toggle" appFeatherIcon></i>
        </a>
      </li>

      <li class="dropdown notification-list align-self-center" ngbDropdown>
        <a class="nav-link dropdown-toggle nav-user mr-0" ngbDropdownToggle href="javascript: void(0);" role="button">
          <div class="media user-profile">
            <img src="assets/images/users/avatar-7.jpg" alt="user-image" class="rounded-circle align-self-center" />
            <div class="media-body text-left">
              <h6 class="pro-user-name ml-2 my-0">
                <span>{{ userName }}</span>
                <span class="pro-user-desc text-muted d-block mt-1">{{ userRoleName }} </span>
              </h6>
            </div>
            <i [attr.data-feather]="'chevron-down'" class="ml-2 align-self-center" appFeatherIcon></i>
          </div>
        </a>
        <div class="dropdown-menu profile-dropdown-items dropdown-menu-right" ngbDropdownMenu>
         <a routerLink="/pages-profile" class="dropdown-item notify-item" ngbDropdownItem>
            <i [attr.data-feather]="'user'" class="icon-dual icon-xs mr-2" appFeatherIcon></i>
            <span>&nbsp;My Account</span>
          </a>

          <a href="javascript:void(0);" class="dropdown-item notify-item" ngbDropdownItem>
            <i [attr.data-feather]="'settings'" class="icon-dual icon-xs mr-2" appFeatherIcon></i>
            <span>&nbsp;Settings</span>
          </a>

          <a href="javascript:void(0);" class="dropdown-item notify-item" ngbDropdownItem>
            <i [attr.data-feather]="'help-circle'" class="icon-dual icon-xs mr-2" appFeatherIcon></i>
            <span>&nbsp;Help</span>
          </a>

        <!--   <a href="javascript: void(0);" class="dropdown-item notify-item" ngbDropdownItem>
            <i [attr.data-feather]="'lock'" class="icon-dual icon-xs mr-2" appFeatherIcon></i>
            <span>&nbsp;Lock Screen</span>
          </a> -->

          <div class="dropdown-divider"></div>

          <a href="javascript:void(0);" (click)="logout()" class="dropdown-item notify-item" ngbDropdownItem>
            <i [attr.data-feather]="'log-out'" class="icon-dual icon-xs mr-2" appFeatherIcon></i>
            <span>&nbsp;Logout</span>
          </a>
        </div>
      </li>
    </ul>
  </div>

</div>
<!-- end Topbar -->
