import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Equipmentplanning,EquipmentMaster } from 'src/app/model';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})

export class EquipmentplanningService {

    public pageName = 'Equipment Planning';
    public dataList = new Subject<Array<Equipmentplanning>>();
    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private common: CommonDataService,
        private msgSvc: MsgService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.equipmentplanningApi[apiOption];
    }

    public getEquipments() {
        const url = this.config.ipAddress + this.config.equipmentMasterApi[ApiActions.Get];
        const equipmentList = new Subject<Array<EquipmentMaster>>();
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.equipmentMasterList) {
                    const list = response.result.equipmentMasterList.filter(e => e.status > this.util.approvedStatus);
                    equipmentList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return equipmentList;
    }

    public getDataRecords() {
        const dataArray: Array<Equipmentplanning> = [];
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.equipmentoperatingtimingsmasterList) {
                    response.result.equipmentoperatingtimingsmasterList.map(element => {
                        dataArray.push(element);
                    });
                }
                this.dataList.next(dataArray);
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public getDetailsDataRecords(eqpcode:string) {
        const fData = new FormData();
        fData.append('eqpcode', eqpcode);
        const url = this.config.ipAddress + this.config.equipmentplanningApi[ApiActions.GetSingle];
        const equipmentdetailsList = new Subject<Array<Equipmentplanning>>();
        this.http.post(url, fData, { headers: undefined })
          .subscribe((response: any) => {
            if (response && response.result && response.result.equipmentoperatingtimingsmasterList) {
              const list = response.result.equipmentoperatingtimingsmasterList.filter(e => e.status > this.util.approvedStatus);
              equipmentdetailsList.next(list);
            }
          },
            err => {
                this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return equipmentdetailsList;
    }
    public updateData(objData: Equipmentplanning) {
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Equipmentplanning) {
        objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Equipmentplanning) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();
        fData.append('status', objData.status + '');
        fData.append('id', objData.id + '');
        fData.append('equipmentId', objData.equipmentId + '');
        fData.append('equipmentAvailable', objData.equipmentAvailabile + '');
        fData.append('equipmentShifts', objData.equipmentShifts);
        fData.append('nonAvailableReason', objData.nonAvailableReason + '');
        fData.append('hoursNormalWageRate', objData.hoursNormalWageRate + '');
        fData.append('hoursOvertimeWageRate', objData.hoursOvertimeWageRate + '');

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }

}

