import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { UserMenus } from 'src/app/model';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UserMenusService {

  public pageName = 'User Menu';
  public userMenusList = new Subject<Array<UserMenus>>();

  constructor(
    private config: AppConfigService,
    private http: HttpClient,
    private msgService: MsgService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.userMenusApi[apiOption];
  }

  public getDataRecords() {
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined }).subscribe((response: any) => {
      if (response && response && response.result) {
        const UserMenusList: Array<UserMenus> = [];
        response.result.userMenus.forEach(element => {
          element.status = element.status == '1' ? true : false;
          UserMenusList.push(element);
        });
        this.userMenusList.next(UserMenusList);
      }
    },
      err => {
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
      });
  }

  public addData(userMenu: UserMenus) {
    const fData = this.fillData(userMenu);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  public updateData(userMenu: UserMenus) {
    const fData = this.fillData(userMenu);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  private fillData(objData: UserMenus) {
    const fData = new FormData();
    fData.append('userId', objData.userId + '');
    fData.append('userManager', objData.userMngr);
    fData.append('menuManageIds', objData.userMenuIds);
    fData.append('status', objData.status ? '1' : '0');
    return fData;
  }

  public updateStatus(objData: UserMenus) {
    const fData = new FormData();
    fData.append('userId', objData.userId + '');
    fData.append('status', objData.status ? '1' : '0');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('userId', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public getMenusData() {
    const url = this.config.ipAddress + this.config.userMenusApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        const userMenusList: Array<UserMenus> = [];
        response.result.userMenus.forEach(element => {
          element.userroleStatus = element.userroleStatus ? true : false;
          userMenusList.push(element);
        });
        this.userMenusList.next(userMenusList);
      }
    },
      err => {
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
      });
  }

}
