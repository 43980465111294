import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiActions } from './util.service';
import { AppConfigService } from './app-config.service';
import { Subject } from 'rxjs';
import { UserDetails } from 'src/app/model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

import jwt_decode from 'jwt-decode';
const tokenProp = 'token';
const userRoleProp = 'user_role';
const userIdProp = 'id';
const userNameProp = 'username';
const userRoleNameProp = 'user_role_code';
const userEmailProp = 'user_email';
const workflowlevel = 'workflowlevel';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private helper = new JwtHelperService();
  private userConfig = {};

  constructor(
    private config: AppConfigService,
    private http: HttpClient,
    private router: Router
  ) {
  //  window.onload = () => { this.logout(); };
  }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.logInApi[apiOption];
  }

  public get isTokenExpired() {
    return false;
  }

  public login(userData: UserDetails) {
    const responseMsg = new Subject<boolean>();

    this.http.post(this.getUrl(ApiActions.Get), userData, { headers: null })
      .subscribe(
        (response: any) => {
          if (response && response.token) {
            localStorage.setItem('currentUser', 'loggedIn');
            localStorage.setItem(tokenProp, response.token);
			this.decodeJwt(response.token);
			 localStorage.setItem('userId', this.userId);
			 localStorage.setItem('userRoleId', this.userRoleId);
			localStorage.setItem('userEmail', this.userEmail);
			localStorage.setItem('userName', this.userName);
			localStorage.setItem('userrolename', this.userRoleName);
      localStorage.setItem('workflowlevel', this.workflowlevel);
           console.log(jwt_decode(response.token));
         //  this.router.navigate(['/home']);
         responseMsg.next(true);
          } else {
            //this.router.navigate(['/account/login']);
            responseMsg.next(false);
          }
        },
        err => {
        //  this.router.navigate(['/account/login']);
        responseMsg.next(false);
          
        }
      );
    return responseMsg;
  }

  /** Return user id */
  public get userId() {
    return this.userConfig ? this.userConfig[userIdProp] : undefined;
  }
  public get userName() {
    return this.userConfig ? this.userConfig[userNameProp] : undefined;
  }
    public get userEmail() {
    return this.userConfig ? this.userConfig[userEmailProp] : undefined;
  }
  /** Return user-role id */
  public get userRoleId() {
    return this.userConfig ? this.userConfig[userRoleProp] : undefined;
  }  
  public get userRoleName() {
    return this.userConfig ? this.userConfig[userRoleNameProp] : undefined;
  }
  public get workflowlevel() {
    return this.userConfig ? this.userConfig[workflowlevel] : undefined;
  }
  public currentUser() {
    if (localStorage.getItem('currentUser') === 'loggedIn') {
      return true;
    }else{
      return false;
    }
  }
  /**
   * Decode the JWT token and store value required in storage.
   * @param rawToken Jwt raw data from response object.
   */
  public decodeJwt(rawToken: string) {
    const data = this.helper.decodeToken(rawToken);
    this.userConfig = data;
    localStorage.setItem('userRoleId', this.userRoleId);
	localStorage.setItem('userEmail', this.userEmail);
	localStorage.setItem('userName', this.userName);
  }

  public logout() {
    localStorage.clear();
    this.router.navigate(['/account/login']);
  }
}
