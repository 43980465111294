import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Salesquatationsagainstenquiry,ProductUOM,ProductMaster,Customer } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})

export class SalesquatationsagainstenquiryService {
    public pageName = 'Sale Quotation Against Enquiry';
    public dataList = new Subject<Array<Salesquatationsagainstenquiry>>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.SalesquatationsagainstenquiryApi[apiOption];
    }

    public getUOMCodes() {
        const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
        const data = new Subject<Array<ProductUOM>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.UnitsOfMeasurement;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }

    public getProductsData() {
        const resultList = new Subject<Array<ProductMaster>>();
        const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Customerorderproducts];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.productMasterList) {
                    const list = response.result.productMasterList;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }

    public getCustomers() {
        const url = this.config.ipAddress + this.config.customerMasterApi[ApiActions.Get];
        const data = new Subject<Array<Customer>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Customermaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getCUrrancyList(): Subject<Array<any>> {
        const url = this.config.ipAddress + this.config.currencyserviceApi[ApiActions.Get];
        const countrylist: Array<any> = [];
        const result = new Subject<Array<any>>();
        this.http.post(url, null, { headers: undefined })
          .subscribe((response: any) => {
            if (response && response.result) {
              response.result.Currency.map(element => {
               // element.status = Number(element.recStatus) === 1 ? true : false;
                countrylist.push(element);
              });
              result.next(countrylist);
            }
          },
            err => {
                this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return result;
      }
    public getEnquiryTypedropdownData() {
        const url = this.config.ipAddress + this.config.SalesquatationsagainstenquiryApi[ApiActions.Create];
        const data = new Subject<Array<any>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }

    public getDataRecords() {
        let resultList = new Array<Salesquatationsagainstenquiry>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.SalesquotationList) {
                    resultList = response.result.SalesquotationList.map(e => {
                      
                            e.QuotDate = new Date(e.QuotDate);
                            e.pdfurl = this.config.ipAddress +"Salesquotation/getSalesquotationtList/"+e.salequot_id;
                        
                        return e;
                    });
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }

    public updateData(objData: Salesquatationsagainstenquiry) {
        console.log(objData);
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Salesquatationsagainstenquiry) {
        objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Salesquatationsagainstenquiry) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append('salequot_id', objData.salequot_id + '');
        fData.append('saleenq_id', objData.saleenq_id + '');
        fData.append('cust_id', objData.customerId + '');
        fData.append('QuotCode', objData.QuotCode + '');

        fData.append('quot_payment_days', objData.quot_payment_days + '');
        fData.append('quot_insurance', objData.quot_insurance + '');
        fData.append('quot_validity', objData.quot_validity + '');
        fData.append('quot_delivery', objData.quot_delivery + '');

        fData.append('Ordcgst', objData.Ordcgst + '');
        fData.append('Ordsgst', objData.Ordsgst + '');
        fData.append('Ordigst', objData.Ordigst + '');

        fData.append('QuotDate', this.util.getDate(objData.QuotDate));

        const items = objData.items.map(e => {
            return {
                salequotdtl_id: e.salequotdtl_id,
                prodId: e.prod_id,
                prodSpecs: e.prodSpecs,
                QuotQty: e.QuotQty,
                QuotPrice: e.QuotPrice,
                currency_id: e.currency_id,
                orderUom: e.uom_id,

            };
        });

        fData.append('items', JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}

