import {Injectable} from '@angular/core';
import {ApiActions,UtilService} from '../common/util.service';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '../common/app-config.service';
import {MsgService} from '../common/msg.service';
import {CommonDataService} from '../common/common-data.service';
import {BatchIdentity,FieldSeperator,RefMasterField} from 'src/app/model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class BatchIdentityService {

    public pageName = 'Batch Number Formations';
    public dataList = new Subject < Array < BatchIdentity >> ();
    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
    ) {}

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.batchidentityApi[apiOption];
    }

    public getCategories(): Subject < Array < FieldSeperator >> {
        const url = this.config.ipAddress + this.config.fieldseparatorApi[ApiActions.Get];
        const dataArray: Array < FieldSeperator > = [];
        const data = new Subject < Array < FieldSeperator >> ();
        this.http.post(url, null, {
            headers: undefined
        })
        .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.RefMasterFieldList.map(element => {
                        element.status = element.status == '1' ? true : false;
                        dataArray.push(element);
                    });
                    data.next(dataArray);
                }
            },
            err => {
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return data;
    }
	
	public getbatchFeilds(): Subject < Array < RefMasterField >> {
        const url = this.config.ipAddress + this.config.fieldsApi[ApiActions.Get];
        const dataArray: Array < RefMasterField > = [];
        const data = new Subject < Array < RefMasterField >> ();
        this.http.post(url, null, {
            headers: undefined
        })
        .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.RefMasterFieldList.map(element => {
                        element.status = element.status == '1' ? true : false;
                        dataArray.push(element);
                    });
                    data.next(dataArray);
                }
            },
            err => {
                  this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
            });
        return data;
    }
    public getDataRecords() {
        const dataArray: Array < BatchIdentity > = [];
        this.http.post(this.getUrl(ApiActions.Get), null, {
                headers: undefined
            })
            .subscribe((response: any) => {
                    if (response && response.result && response.result.BatchidentityList) {
                        response.result.BatchidentityList.map(element => {
                            dataArray.push(element);
                        });
                    }
                    this.dataList.next(dataArray);
                },
                err => {
                      this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
                });
    }
    private fillData(objData: BatchIdentity) {
        const fData = new FormData();
        fData.append('batch_field_id', objData.batch_field_id + '');
        fData.append('field1_name', objData.field1_name+ '');
		fData.append('field2_name', objData.field2_name+ '');
		fData.append('field3_name', objData.field3_name+ '');
		fData.append('field4_name', objData.field4_name+ '');
		fData.append('field5_name', objData.field5_name+ '');
        fData.append('field_symbal', objData.field_symbal+ '');
		fData.append('no_fields', objData.no_fields+ '');
        fData.append('status', objData.status ? '1' : '0');
        return fData;
    }
    public updateData(objData: BatchIdentity) {
        const fData = this.fillData(objData);
        return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);

    }

    public deleteData(id: number) {
        const fData = new FormData();
        fData.append('batch_field_id', id + '');
        return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
    }
    public Save(objData: BatchIdentity) {
        const fData = this.fillData(objData);
        return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
    }
}