import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Materialtransfernote } from 'src/app/model';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { ApiActions, UtilService } from '../common/util.service';
import { CommonDataService } from '../common/common-data.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class MaterialtransfernoteService {
  public pageName = 'Material Transfer Note';
  public dataList = new Subject<Array<Materialtransfernote>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private common: CommonDataService,
    private msgSvc: MsgService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.MaterialtransfernoteApi[apiOption];
  }

  public getDataRecords(fromDate: Date, toDate: Date) {
    let resultList = new Array<Materialtransfernote>();
    const fData = new FormData();
    fData.append('fromDate', this.util.getDate(fromDate));
    fData.append('toDate', this.util.getDate(toDate));
    this.http.post(this.getUrl(ApiActions.Get), fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          resultList = response.result.LOTPlanningOrders.map(e => {
            e.run_end_date = new Date(e.run_end_date);
            return e;
          });
          this.dataList.next(resultList);
        }
      },
        err => {
          this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public updateData(objList:Materialtransfernote) {
    return this.Save(this.getUrl(ApiActions.Update), objList);
  }

  private Save( action: string, objList) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();

    fData.append('BatchNum', objList.BatchNum + '');

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }


}
