import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Paystructure } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class PaystructureService {

  public pageName = 'Pay Structure';
  public dataList = new Subject<Array<Paystructure>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.PaystructureApi[apiOption];
  }

  public getPaycomponents() {
    const resultList = new Subject<Array<any>>();
    const url = this.config.ipAddress + this.config.PaycomponentsApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
        .subscribe((response: any) => {
            if (response && response.result && response.result.PaycomponentsList) {
                const list = response.result.PaycomponentsList;
                resultList.next(list);
            }
        },
            err => {
                this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
            });
    return resultList;
}
public getEmployee() {
  const resultList = new Subject<Array<any>>();
  const url = this.config.ipAddress + this.config.EmployeeDetailsApi[ApiActions.Get];
  this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
          if (response && response.result && response.result.Employeedetails) {
              const list = response.result.Employeedetails;
              resultList.next(list);
          }
      },
          err => {
              this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
          });
  return resultList;
}
  public getDataRecords() {
    let resultList = new Array<Paystructure>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
    .subscribe((response: any) => {
      console.log(response);
        if (response && response.result && response.result.PaystructureList) {
            resultList = response.result.PaystructureList;
            this.dataList.next(resultList);
        }
    },
        err => {
            //this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
        });


  }

  public saveDraft(objData:Paystructure) {
    return this.Save(this.getUrl(ApiActions.Create), objData);

  }
  public updateData(objData:Paystructure) {
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  private Save(action: string, objData: Paystructure) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('emp_id', objData.emp_id + '');

    const items = objData.items.map(e => {
        return {
          paystru_id: e.paystru_id,
          paycomp_id: e.paycomp_id,
          paycomp_val: e.paycomp_val,
          add_deduct: e.add_deduct,

        };
    });

    fData.append('items', JSON.stringify(items));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
        if (response) {
            this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
            responseMsg.next('');
        }
    },
        err => {
            responseMsg.next(err);
        });

    return responseMsg;
}
}
