import { Component, OnInit,NgModule  } from '@angular/core';
import { Title,BrowserModule } from '@angular/platform-browser';
import { PrimeIcons } from "primeng/api";
import {
  AppConfigService
  , BusyCursorService
  , AuthenticationService
  , LeftMenuService,
  MenuItem,
  defaultCSS 
} from './service';
import { Router, Event as NavigationEvent, NavigationStart } from '@angular/router';
import { Calendar } from '@fullcalendar/core';
/** Dynamic js required for UI actions. */
const url = 'assets/js/app.min.4fc8dd6e.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private pageName = '';
  public doReset = false;
  public showProgress = false;
  public dateTimeNow = '';
  public userName = '';
  public userEmail = '';
  public userRoleName = '';
  public currentPage = 'Home';
  public menus: Array<MenuItem> = [];
  public notificationCount = 0;
  public showNotifications = false;
  public hasNotifications = false;


  public get isLogin() {
    return this.pageName === 'login';
  }

  public menuToggleCSS = defaultCSS;
 activityList: any[];
  constructor(
    private title: Title,
    private router: Router,
    private busySvc: BusyCursorService,
    private authSvc: AuthenticationService,
    private menuSvc: LeftMenuService,
    public config: AppConfigService) {

    this.title.setTitle(config.title);

   
	//console.log(this.authSvc);
    this.busySvc.isShowBusy.subscribe((value) => {
      this.showProgress = value;
	  
    });

    this.router.events.subscribe(
      (event: NavigationEvent) => {
        if (this.pageName !== 'login') {
          if (event instanceof NavigationStart && event.url === '/login') {
            this.pageName = 'login';
          }
        }
      });

    this.menuSvc.toggleMenu().subscribe(val => {
      this.menuToggleCSS = val;
    });

	this.menuSvc.toggleMenu().subscribe(val => {
      this.menuToggleCSS = val;
    });


    // // TODO: Remove
     //this.loadMenus(null);

    this.menuSvc.menus.subscribe(menuData => {
      this.loadNotifications();
      this.loadMenus(menuData);
    });
  }

  ngOnInit(): void {
    this.pageName = 'login';
    this.dateTimeNowEvent();
	

  }

  private dateTimeNowEvent() {
    setInterval(() => {
      this.dateTimeNow = new Date().toLocaleString('en-GB');
    }, 1000);
  }

  public loadNotifications() {
	  	this.menuSvc.getActivityList().subscribe(val => {
      this.activityList = val;
    });
    this.menuSvc.getNotificationCount().subscribe(val => {
      this.notificationCount = val;
      this.hasNotifications = true;
    });
  }

  public loadScript() {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  public loadMenus(menuData: Array<MenuItem>) {
    const tmpMenus = [];
    console.log(menuData);
    this.menus = tmpMenus;
    this.runScript();
  }

configone = {
paddingAtStart: true,
    interfaceWithRoute: true,
    classname: 'my-cool-menu-item',
    selectedListFontColor: `red`,
    highlightOnSelect: true,
    collapseOnSelect: true,
    useDividers: true,
    rtlLayout: false
};
  public runScript() {
    const loadAPI = new Promise(resolve => { this.loadScript(); });
  }

  public loginAction(event) {
    this.pageName = 'home';
    this.menuSvc.getMenus();
	 this.userName = localStorage.getItem('userName');
	this.userEmail = localStorage.getItem('userEmail');
	this.userRoleName = localStorage.getItem('userrolename');
    this.router.navigate(['/home']);
  }

  public logout() {
    this.router.navigate(['/login']);
    this.pageName = 'login';
    setTimeout(() => location.reload(), 100);
  }

  public menuItemSelect(menu) {
    this.currentPage = menu;
  }

  public gotoHome() {
    this.router.navigate(['/home']);
    this.currentPage = 'Dashboard';
  }

  public showNotificationsDlg() {
    this.showNotifications = true && (this.notificationCount > 0);
  }
 appitems =  [
      {
        label: 'Admin',
		faIcon: 'fa fa-arrow-circle-right',
        items: [
          { label: 'Users', link: 'user',faIcon: 'fa fa-angle-double-right'},
          { label: 'Main Menu', link: 'admin-menu',faIcon: 'fa fa-angle-double-right' },
          // { label: 'Sub Menu', link: 'home' },
          { label: 'User Roles', link: 'user-roles',faIcon: 'fa fa-angle-double-right' },
        ]
      },
      {
        label: 'Masters',
		faIcon: 'fa fa-arrow-circle-right',
        items: [
		  { label: 'Product Master', link: 'product-master',faIcon: 'fa fa-angle-double-right' },
          { label: 'Product Specification', link: 'product-specdoc',faIcon: 'fa fa-angle-double-right' },
          { label: 'Batch Formation', link: 'batch-identity',faIcon: 'fa fa-angle-double-right' },
		  { label: 'Item Master', link: 'item-master',faIcon: 'fa fa-angle-double-right' },
		  { label: 'Make(or) Buy', link: 'intermediates',faIcon: 'fa fa-angle-double-right' },
          { label: 'Equipment Master', link: 'equipment-master',faIcon: 'fa fa-angle-double-right' },
          { label: 'Customer Master', link: 'customer-master',faIcon: 'fa fa-angle-double-right' },
          { label: 'Unit Master', link: 'unit-master',faIcon: 'fa fa-angle-double-right' },
          { label: 'Unit Line Master', link: 'unit-line-master',faIcon: 'fa fa-angle-double-right' },
          { label: 'Master Formula', link: 'master-formula',faIcon: 'fa fa-angle-double-right' },
          { label: 'Process Configuration', link: 'process-config',faIcon: 'fa fa-angle-double-right' }
        ]
		
      },
      {
        label: 'Sub Masters',
		faIcon: 'fa fa-arrow-circle-right',
        items: [
		  { label: 'Batch Fields', link: 'batch-fields',faIcon: 'fa fa-angle-double-right' },
          { label: 'Batch Seperator', link: 'batch-seperator',faIcon: 'fa fa-angle-double-right' },
		 		
          { label: 'Product Category', link: 'product-category',faIcon: 'fa fa-angle-double-right' },
		  { label: 'Product Physical Form', link: 'product-physical-form',faIcon: 'fa fa-angle-double-right' },
          { label: 'Product Release Profile', link: 'product-grade',faIcon: 'fa fa-angle-double-right' },
          { label: 'Product Pharmacopiea', link: 'product-pharmacopiea',faIcon: 'fa fa-angle-double-right' },
          { label: 'Product Parameter', link: 'product-parameter',faIcon: 'fa fa-angle-double-right' },
		  { label: 'Product Type', link: 'product-type',faIcon: 'fa fa-angle-double-right' },
          { label: 'Item Form', link: 'item-form',faIcon: 'fa fa-angle-double-right' },
		  
		  { label: 'Item Category', link: 'ref-item-category',faIcon: 'fa fa-angle-double-right' },
          { label: 'Item Class', link: 'ref-item-class',faIcon: 'fa fa-angle-double-right' },
          { label: 'Item Grade', link: 'ref-item-grade',faIcon: 'fa fa-angle-double-right' },
		  { label: 'Item Type', link: 'item-type',faIcon: 'fa fa-angle-double-right' },
          { label: 'Equipment Type', link: 'equipment-type',faIcon: 'fa fa-angle-double-right' },
          { label: 'Equipment SubType', link: 'equipment-sub-type',faIcon: 'fa fa-angle-double-right' },
          { label: 'Line Master', link: 'line-master',faIcon: 'fa fa-angle-double-right' },
          { label: 'Customer Type', link: 'customer-type',faIcon: 'fa fa-angle-double-right' },
          { label: 'Process Configuration Steps', link: 'process-config-steps',faIcon: 'fa fa-angle-double-right' },
          { label: 'Process Configuration Params', link: 'process-config-params',faIcon: 'fa fa-angle-double-right' }
        ]
      },
	 
      {
        label: 'Production Scheduling Steps',
		faIcon: 'fa fa-arrow-circle-right',
        items: [
			{
			label: 'Shift Details',
			faIcon: 'fa fa-arrow-circle-right',
			items: [
				{ label: 'Unit wise Shift Timings', link: 'unit-wise-shift-timing',faIcon: 'fa fa-angle-double-right' }
			]
		  },
		  {
			label: 'Equipment Details',
			faIcon: 'fa fa-arrow-circle-right',
			items: [
				{ label: 'Equipment Operating Timing', link: 'equipment-operating-timing',faIcon: 'fa fa-angle-double-right' },
				{ label: 'Equipment Line Clearance', link: 'equipment-line-clearance-timings',faIcon: 'fa fa-angle-double-right' }
			]
		  },
          { label: 'Customer Order', link: 'customer-order',faIcon: 'fa fa-angle-double-right' },
		  { label: 'Internal Order', link: 'internal-order',faIcon: 'fa fa-angle-double-right' }, 
		  { label: 'Sales Forecast', link: 'sales-forecast',faIcon: 'fa fa-angle-double-right' },
          { label: 'LOT Priority Planning', link: 'lot-priority-planning',faIcon: 'fa fa-angle-double-right' },
          { label: 'Batch Sequence Generator', link: 'product-sequence-generator',faIcon: 'fa fa-angle-double-right' },
          { label: 'Batch Schedule', link: 'process-scheduler',faIcon: 'fa fa-angle-double-right' },
		  { label: 'Plan Comparison', link: 'plan-comparison',faIcon: 'fa fa-angle-double-right' },
		  {
			label: 'Daily Updates',
			faIcon: 'fa fa-arrow-circle-right',
			items: [
				{ label: 'Daily Production Log', link: 'daily-production-log',faIcon: 'fa fa-angle-double-right' },
				{ label: 'Dispatch Quantity', link: 'dispatch-quantity',faIcon: 'fa fa-angle-double-right' },
			]
		  },
		  
        ]
      } 
	  
    ];
  
}
