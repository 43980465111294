import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Sostockupdates, ProductUOM, ProductMaster, Customer } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class SostockupdatesService {

    public pageName = 'Sale Stock Updates';
    public dataList = new Subject<Array<Sostockupdates>>();
    public OrderdataList = new Subject<Array<Sostockupdates>>();
    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.SostockupdatesApi[apiOption];
    }
    public getUOMCodes() {
        const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
        const data = new Subject<Array<ProductUOM>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.UnitsOfMeasurement;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }

    public getProductsData() {
        const resultList = new Subject<Array<ProductMaster>>();
        const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Customerorderproducts];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.productMasterList) {
                    const list = response.result.productMasterList;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }

    public getCustomers() {
        const url = this.config.ipAddress + this.config.customerMasterApi[ApiActions.Get];
        const data = new Subject<Array<Customer>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Customermaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getCUrrancyList(): Subject<Array<any>> {
        const url = this.config.ipAddress + this.config.currencyserviceApi[ApiActions.Get];
        const countrylist: Array<any> = [];
        const result = new Subject<Array<any>>();
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.Currency.map(element => {
                        // element.status = Number(element.recStatus) === 1 ? true : false;
                        countrylist.push(element);
                    });
                    result.next(countrylist);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return result;
    }
    public getEnquiryTypedropdownData(fromdate:Date,todate:Date) {
        const url = this.config.ipAddress + this.config.SostockupdatesApi[ApiActions.Create];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("fromdate", this.util.getDate(fromdate));
        fData.append("todate", this.util.getDate(todate));
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getRecivablecodedropdownData(OrderId:number) {
        const url = this.config.ipAddress + this.config.SostockupdatesApi[ApiActions.UpdateStatus];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("order_id",OrderId +"");
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getDataRecords() {
        let resultList = new Array<Sostockupdates>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.SostockupdatesList) {
                    resultList = response.result.SostockupdatesList.map(e => {
                        e.QuotDate = new Date(e.QuotDate);
                        e.OrdDate = new Date(e.OrdDate);
                        e.IssuedDate = new Date(e.IssuedDate);

                        return e;
                    });
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public getOrderDataRecords(orderId:number) {
        let resultList = new Array<Sostockupdates>();
        const fData = new FormData();

        fData.append("order_id",orderId +"");
        this.http.post(this.getUrl(ApiActions.Delete), fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.SostockupdatesList) {
                    resultList = response.result.SostockupdatesList.map(e => {
                        e.QuotDate = new Date(e.QuotDate);
                        e.OrdDate = new Date(e.OrdDate);
                        e.IssuedDate = new Date(e.IssuedDate);

                        return e;
                    });
                    this.OrderdataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public updateData(objData: Sostockupdates) {
        console.log(objData);
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Sostockupdates) {
        objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Sostockupdates) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append('saleissued_id', objData.saleissued_id + '');
        fData.append('saleord_id', objData.saleord_id + '');
        fData.append('salereturn_id', objData.salereturn_id + '');
        fData.append('IssuedCode', objData.IssuedCode + '');
        fData.append('IssuedDate', this.util.getDate(objData.IssuedDate));

        const items = objData.items.map(e => {
            return {
                saleissueddtl_id: e.saleissueddtl_id,
                prodId: e.prod_id,
                ProdSpecs: e.ProdSpecs,
                stkupd_qty: e.StockUpdQty,
                IssuedQty: e.IssuedQty,
                orderUom: e.uom_id,

            };
        });

        fData.append('items', JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}

