import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Employeedetails, Department,Address, Designation, Employeetype } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class EmployeedetailsService {
    public pageName = 'Employee Details';
    public dataList = new Subject<Array<Employeedetails>>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.EmployeeDetailsApi[apiOption];
    }

    public getDepartment() {
        const url = this.config.ipAddress + this.config.DepartmentApi[ApiActions.Get];
        const data = new Subject<Array<Department>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Department;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }

    public getEmployeetypeData() {
        const resultList = new Subject<Array<Employeetype>>();
        const url = this.config.ipAddress + this.config.EmployeetypeApi[ApiActions.Get];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.Employeetype) {
                    const list = response.result.Employeetype;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }
    public getEmployeeAddressData(Address_id:number,address_type_id:number) {
        const resultList = new Subject<Address>();
        const url = this.config.ipAddress + this.config.AddressApi[ApiActions.Delete];
        const fData = new FormData();
        fData.append('empaddressid', Address_id + '');
        fData.append('empaddress_typeid', address_type_id + '');
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.Address) {
                    const list = response.result.Address;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }
    public getDesignationData() {
        const resultList = new Subject<Array<Designation>>();
        const url = this.config.ipAddress + this.config.DesignationApi[ApiActions.Get];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.Designation) {
                    const list = response.result.Designation;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }
    public getAddressTypeData() {
        const resultList = new Subject<Array<any>>();
        const url = this.config.ipAddress + this.config.AddressApi[ApiActions.DraftSave];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.Address) {
                    const list = response.result.Address;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }
    public getCountryData() {
        const resultList = new Subject<Array<any>>();
        const url = this.config.ipAddress + this.config.CountryApi[ApiActions.Get];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.Country) {
                    const list = response.result.Country;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }
    public getStateData() {
        const resultList = new Subject<Array<any>>();
        const url = this.config.ipAddress + this.config.StateApi[ApiActions.Get];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.State) {
                    const list = response.result.State;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }
    public getCityData() {
        const resultList = new Subject<Array<any>>();
        const url = this.config.ipAddress + this.config.CityApi[ApiActions.Get];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.City) {
                    const list = response.result.City;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }

    public getEmployeeListData() {
        const resultList = new Subject<Array<any>>();
        const url = this.config.ipAddress + this.config.EmployeeDetailsApi[ApiActions.Get];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.Employeedetails) {
                    const list = response.result.Employeedetails;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }

    public getDataRecords() {
        let resultList = new Array<Employeedetails>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.Employeedetails) {
                    resultList = response.result.Employeedetails.map(e => {

                        e.emp_jdate = new Date(e.emp_jdate);
                        e.emp_dob = new Date(e.emp_dob);
                        e.emp_rdate = new Date(e.emp_rdate);

                        return e;
                    });
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }

    public updateData(objData: Employeedetails) {
        console.log(objData);
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Employeedetails) {
        return this.Save(this.getUrl(ApiActions.Create), objData);
    }
    public saveDraftAddress(objData: Employeedetails,objaddData: Address) {
        return this.SaveAdd(this.getUrl(ApiActions.Create), objData,objaddData);
    }

    private Save(action: string, objData: Employeedetails) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append('emp_id', objData.emp_id + '');
        fData.append('account_name', objData.account_name + '');
        fData.append('account_num', objData.account_num + '');
        fData.append('address_id', objData.address_id + '');
        fData.append('agreement_path', objData.agreement_path + '');
        fData.append('bank_branch', objData.bank_branch + '');
        fData.append('bank_id', objData.bank_id + '');

        fData.append('bank_ifsc', objData.bank_ifsc + '');
        fData.append('bank_name', objData.bank_name + '');
        fData.append('email_pers', objData.email_pers + '');
        fData.append('email_work', objData.email_work + '');
        fData.append('emp_aadhar', objData.emp_aadhar + '');
        fData.append('emp_code', objData.emp_code + '');
        fData.append('emp_dob', this.util.getDate(objData.emp_dob));
        fData.append('emp_father', objData.emp_father + '');
        fData.append('emp_fname', objData.emp_fname + '');
        fData.append('emp_gender', objData.emp_gender + '');
        fData.append('emp_image', objData.emp_image + '');
        fData.append('emp_jdate', this.util.getDate(objData.emp_jdate));
        fData.append('emp_lname', objData.emp_lname + '');

        fData.append('emp_mobile', objData.emp_mobile + '');
        fData.append('emp_pan', objData.emp_pan + '');
        fData.append('emp_rdate', this.util.getDate(objData.emp_rdate));
        fData.append('empdept_id', objData.empdept_id + '');
        fData.append('empdesi_id', objData.empdesi_id + '');
        fData.append('emptype_id', objData.emptype_id + '');

        fData.append('idproof_aadhar', objData.idproof_aadhar + '');
        fData.append('idproof_pan', objData.idproof_pan + '');
        fData.append('joinltr_path', objData.joinltr_path + '');
        fData.append('offltr_path', objData.offltr_path + '');
        fData.append('resume_path', objData.resume_path + '');


        //   const items = objData.items.map(e => {
        //       return {
        //         saleorddtl_id: e.saleorddtl_id,
        //           prodId: e.prod_id,
        //           prodSpecs: e.prodSpecs,
        //           QuotQty: e.QuotQty,
        //           OrdQty: e.OrdQty,
        //           QuotPrice: e.QuotPrice,
        //           currency_id: e.currency_id,
        //           orderUom: e.quot_uom,

        //       };
        //   });

        //   fData.append('items', JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }

    private SaveAdd(action: string, objeData: Employeedetails,objaddData: Address) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        const url = this.config.ipAddress + this.config.AddressApi[ApiActions.Create];

        fData.append('emp_id', objeData.emp_id + '');
        fData.append('address_id', objaddData.address_id + '');
        fData.append('addressdtl_id', objaddData.addressdtl_id + '');
        fData.append('addtype_id', objaddData.addtype_id + '');
        fData.append('area_name', objaddData.area_name + '');
        fData.append('country_id', objaddData.country_id + '');
        fData.append('dist_id', objaddData.dist_id + '');
        fData.append('state_id', objaddData.state_id + '');
        fData.append('street_1', objaddData.street_1 + '');
        fData.append('street_2', objaddData.street_2 + '');
        fData.append('email_id', objaddData.email_id + '');
        fData.append('phone_no', objaddData.phone_no + '');
        fData.append('pin_code', objaddData.pin_code + '');

        this.http.post(url, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}
