import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Goodsreceivable, ProductUOM, ProductMaster, Customer } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class GoodsreceivableService {

    public pageName = 'Goods Receipt Note';
    public dataList = new Subject<Array<Goodsreceivable>>();
    public OrderdataList = new Subject<Array<Goodsreceivable>>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.GoodsreceivableApi[apiOption];
    }

    public getUOMCodes() {
        const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
        const data = new Subject<Array<ProductUOM>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.UnitsOfMeasurement;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }

    public getProductsData() {
        const resultList = new Subject<Array<ProductMaster>>();
        const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Customerorderproducts];
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.productMasterList) {
                    const list = response.result.productMasterList;
                    resultList.next(list);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return resultList;
    }

    public getCustomers() {
        const url = this.config.ipAddress + this.config.SupplierMasterApi[ApiActions.Get];
        const data = new Subject<Array<Customer>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Supplierlistmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getCUrrancyList(): Subject<Array<any>> {
        const url = this.config.ipAddress + this.config.currencyserviceApi[ApiActions.Get];
        const countrylist: Array<any> = [];
        const result = new Subject<Array<any>>();
        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    response.result.Currency.map(element => {
                        // element.status = Number(element.recStatus) === 1 ? true : false;
                        countrylist.push(element);
                    });
                    result.next(countrylist);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return result;
    }
    public getEnquiryTypedropdownData(fromdate:Date,todate:Date) {
        const url = this.config.ipAddress + this.config.GoodsreceivableApi[ApiActions.Create];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("fromdate", this.util.getDate(fromdate));
        fData.append("todate", this.util.getDate(todate));
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getEnquiryTypedropdownDataSupplier(cust_id:number) {
        console.log(cust_id);
        const url = this.config.ipAddress + this.config.GoodsreceivableApi[ApiActions.GetSingle];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("cust_id", cust_id+'');
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getRecivablecodedropdownData(OrderId:number) {
        const url = this.config.ipAddress + this.config.GoodsreceivableApi[ApiActions.UpdateStatus];
        const data = new Subject<Array<any>>();
        const fData = new FormData();

        fData.append("order_id",OrderId +"");
        this.http.post(url, fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.Packmaster;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getDataRecords() {
        let resultList = new Array<Goodsreceivable>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.GoodsreceivableList) {
                    resultList = response.result.GoodsreceivableList.map(e => {

                        e.QuotDate = new Date(e.QuotDate);
                        e.OrdDate = new Date(e.OrdDate);
                        e.IssuedDate = new Date(e.IssuedDate);
                        e.ReceiveDate = new Date(e.ReceiveDate);

                        return e;
                    });
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public getOrderDataRecords(orderId:number) {
        let resultList = new Array<Goodsreceivable>();
        const fData = new FormData();

        fData.append("order_id",orderId +"");
        this.http.post(this.getUrl(ApiActions.Delete), fData, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.GoodsreceivableList) {
                    resultList = response.result.GoodsreceivableList.map(e => {

                        e.QuotDate = new Date(e.QuotDate);
                        e.OrdDate = new Date(e.OrdDate);
                        e.IssuedDate = new Date(e.IssuedDate);
                        e.ReceiveDate = new Date(e.ReceiveDate);

                        return e;
                    });
                    this.OrderdataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }
    public updateData(objData: Goodsreceivable) {
        console.log(objData);
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Goodsreceivable) {
        objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Goodsreceivable) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append("purord_id", objData.purord_id + "");
        fData.append("purreceive_id", objData.purreceive_id + "");
        fData.append("purenq_id", objData.purenq_id + "");
        fData.append("supp_id", objData.supp_id + "");
        fData.append('ReceiveCode', objData.ReceiveCode + '');
        fData.append('ReceiveDate', this.util.getDate(objData.ReceiveDate));

        const items = objData.items.map((e) => {
            return {
                purreceivedtl_id: e.purreceivedtl_id,
                prodId: e.item_id,
                prodSpecs: e.ItemSpecs,
                orderUom: e.uom_id,
                ReceiveQty: e.ReceiveQty,
                QuotPrice: e.QuotPrice,
                currency_id: e.currency_id,
                OrdQty: e.OrdQty,
            };
        });

        fData.append('items', JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}

