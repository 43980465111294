import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Bankdetails,ProductUOM,ProductMaster,Customer } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class BankdetailsService {
  public pageName = 'Sale Order';
  public dataList = new Subject<Array<Bankdetails>>();

  constructor(
      private http: HttpClient,
      private config: AppConfigService,
      private msgService: MsgService,
      private util: UtilService,
      private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
      return this.config.ipAddress + this.config.BankdetailsApi[apiOption];
  }

  public getUOMCodes() {
      const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
      const data = new Subject<Array<ProductUOM>>();

      this.http.post(url, null, { headers: undefined })
          .subscribe((response: any) => {
              if (response && response.result) {
                  const result = response.result.UnitsOfMeasurement;
                  data.next(result);
              }
          },
              err => {
                  this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
              });
      return data;
  }

  public getProductsData() {
      const resultList = new Subject<Array<ProductMaster>>();
      const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Customerorderproducts];
      this.http.post(url, null, { headers: undefined })
          .subscribe((response: any) => {
              if (response && response.result && response.result.productMasterList) {
                  const list = response.result.productMasterList;
                  resultList.next(list);
              }
          },
              err => {
                  this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
              });
      return resultList;
  }

  public getCustomers() {
      const url = this.config.ipAddress + this.config.customerMasterApi[ApiActions.Get];
      const data = new Subject<Array<Customer>>();

      this.http.post(url, null, { headers: undefined })
          .subscribe((response: any) => {
              if (response && response.result) {
                  const result = response.result.Customermaster;
                  data.next(result);
              }
          },
              err => {
                  this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
              });
      return data;
  }
  public getCUrrancyList(): Subject<Array<any>> {
      const url = this.config.ipAddress + this.config.currencyserviceApi[ApiActions.Get];
      const countrylist: Array<any> = [];
      const result = new Subject<Array<any>>();
      this.http.post(url, null, { headers: undefined })
        .subscribe((response: any) => {
          if (response && response.result) {
            response.result.Currency.map(element => {
             // element.status = Number(element.recStatus) === 1 ? true : false;
              countrylist.push(element);
            });
            result.next(countrylist);
          }
        },
          err => {
              this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
          });
      return result;
    }
  public getEnquiryTypedropdownData() {
      const url = this.config.ipAddress + this.config.BankdetailsApi[ApiActions.Create];
      const data = new Subject<Array<any>>();

      this.http.post(url, null, { headers: undefined })
          .subscribe((response: any) => {
              if (response && response.result) {
                  const result = response.result.Packmaster;
                  data.next(result);
              }
          },
              err => {
                  this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
              });
      return data;
  }

  public getDataRecords() {
      let resultList = new Array<Bankdetails>();
      this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
          .subscribe((response: any) => {
              if (response && response.result && response.result.BankdetailsList) {
                  resultList = response.result.BankdetailsList.map(e => {
                    
                          e.QuotDate = new Date(e.QuotDate);
                          e.OrdDate = new Date(e.OrdDate);
                          e.saleenq_date = new Date(e.saleenq_date);
                      
                      return e;
                  });
                  this.dataList.next(resultList);
              }
          },
              err => {
                  this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
              });
  }

  public updateData(objData: Bankdetails) {
      console.log(objData);
      objData.status = this.util.getSaveStatus();
      return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: Bankdetails) {
      objData.status = this.util.getDraftStatus();
      return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: Bankdetails) {
      const responseMsg = new Subject<string>();
      const fData = new FormData();

    //   fData.append('salequot_id', objData.salequot_id + '');
    //   fData.append('saleord_id', objData.saleord_id + '');
    //   fData.append('OrdCode', objData.OrdCode + '');
    //   fData.append('OrdDate', this.util.getDate(objData.OrdDate));

    //   const items = objData.items.map(e => {
    //       return {
    //         saleorddtl_id: e.saleorddtl_id,
    //           prodId: e.prod_id,
    //           prodSpecs: e.prodSpecs,
    //           QuotQty: e.QuotQty,
    //           OrdQty: e.OrdQty,
    //           QuotPrice: e.QuotPrice,
    //           currency_id: e.currency_id,
    //           orderUom: e.quot_uom,

    //       };
    //   });

    //   fData.append('items', JSON.stringify(items));

      this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
          if (response) {
              this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
              responseMsg.next('');
          }
      },
          err => {
              responseMsg.next(err);
          });

      return responseMsg;
  }
}
