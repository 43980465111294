import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Address,ProductUOM,Country,State } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class AddressService {
  public pageName = 'Sale Order';
  public dataList = new Subject<Array<Address>>();

  constructor(
      private http: HttpClient,
      private config: AppConfigService,
      private msgService: MsgService,
      private util: UtilService,
      private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
      return this.config.ipAddress + this.config.AddressApi[apiOption];
  }

  public getCountrys(): Subject<Array<Country>> {
    const url = this.config.ipAddress + this.config.CountryApi[ApiActions.Get];
    const dataArray: Array<Country> = [];
    const data = new Subject<Array<Country>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Country.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }
  public getStates(): Subject<Array<State>> {
    const url = this.config.ipAddress + this.config.StateApi[ApiActions.Get];
    const dataArray: Array<State> = [];
    const data = new Subject<Array<State>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.State.map(element => {
            element.status = Number(element.rec_status) === 1 ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }
  public getDataRecords() {
      let resultList = new Array<Address>();
      this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
          .subscribe((response: any) => {
              if (response && response.result && response.result.AddressList) {
                  resultList = response.result.AddressList.map(e => {

                          e.QuotDate = new Date(e.QuotDate);
                          e.OrdDate = new Date(e.OrdDate);
                          e.saleenq_date = new Date(e.saleenq_date);

                      return e;
                  });
                  this.dataList.next(resultList);
              }
          },
              err => {
                  this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
              });
  }

  public updateData(objData: Address) {
      console.log(objData);
      objData.status = this.util.getSaveStatus();
      return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: Address) {
      objData.status = this.util.getDraftStatus();
      return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: Address) {
      const responseMsg = new Subject<string>();
      const fData = new FormData();

    //   fData.append('salequot_id', objData.salequot_id + '');
    //   fData.append('saleord_id', objData.saleord_id + '');
    //   fData.append('OrdCode', objData.OrdCode + '');
    //   fData.append('OrdDate', this.util.getDate(objData.OrdDate));

    //   const items = objData.items.map(e => {
    //       return {
    //         saleorddtl_id: e.saleorddtl_id,
    //           prodId: e.prod_id,
    //           prodSpecs: e.prodSpecs,
    //           QuotQty: e.QuotQty,
    //           OrdQty: e.OrdQty,
    //           QuotPrice: e.QuotPrice,
    //           currency_id: e.currency_id,
    //           orderUom: e.quot_uom,

    //       };
    //   });

    //   fData.append('items', JSON.stringify(items));

      this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
          if (response) {
              this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
              responseMsg.next('');
          }
      },
          err => {
              responseMsg.next(err);
          });

      return responseMsg;
  }
}
