import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { Supplierlist, SupplierlistType, ServiceActions } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SupplierlistService implements ServiceActions {
  public pageName = 'Supplier Master';
  public dataList = new Subject<Array<Supplierlist>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.SupplierMasterApi[apiOption];
  }
  public getCountryList(): Subject<Array<any>> {
    const url = this.config.ipAddress + this.config.countryserviceApi[ApiActions.Get];
    const countrylist: Array<any> = [];
    const result = new Subject<Array<any>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Country.map(element => {
           // element.status = Number(element.recStatus) === 1 ? true : false;
            countrylist.push(element);
          });
          result.next(countrylist);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return result;
  }


  public getSupplierlistTypes(): Subject<Array<SupplierlistType>> {
    const url = this.config.ipAddress + this.config.SupplierTypeApi[ApiActions.Get];
    const dataArray: Array<SupplierlistType> = [];
    const result = new Subject<Array<SupplierlistType>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Supplierlistmaster.map(element => {
            element.status = Number(element.recStatus) === 1 ? true : false;
            dataArray.push(element);
          });
          result.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return result;
  }

  public getDataRecords() {
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<Supplierlist> = [];
          response.result.Supplierlistmaster.map(element => {
            element.status = Number(element.recStatus) === 1 ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public addData(objData: Supplierlist) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  private fillData(objData: Supplierlist) {
    const fData = new FormData();
    fData.append('custId', objData.custId + '');
    fData.append('custCode', objData.custCode);
    fData.append('custName', objData.custName);
    fData.append('custCountry', objData.custCountry+'');
    fData.append('custtypeId', objData.custtypeId + '');
    fData.append('custAddress', objData.custAddress);
    fData.append('custcontactNumber', objData.custcontactNumber + '');
    fData.append('custcontactName', objData.custcontactName);
    fData.append('status', objData.status ? '1' : '0');
    return fData;
  }

  public updateData(objData: Supplierlist) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('custId', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: Supplierlist) {
    const fData = new FormData();
    fData.append('stat', objData.status ? '1' : '0');
    fData.append('custId', objData.custId + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

}
