import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { CommonDataService } from '../common/common-data.service';
import { Purchasesorders, ProductUOM, ProductMaster, Customer } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class PurchasesordersService {
    public pageName = 'Purchase Order';
    public dataList = new Subject<Array<Purchasesorders>>();

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private msgService: MsgService,
        private util: UtilService,
        private router: Router
    ) { }

    private getUrl(apiOption: ApiActions) {
        return this.config.ipAddress + this.config.PurchasesordersApi[apiOption];
    }

    public getCUrrancyList(): Subject<Array<any>> {
        const url = this.config.ipAddress + this.config.currencyserviceApi[ApiActions.Get];
        const countrylist: Array<any> = [];
        const result = new Subject<Array<any>>();
        this.http.post(url, null, { headers: undefined }).subscribe(
            (response: any) => {
                if (response && response.result) {
                    response.result.Currency.map((element) => {
                        // element.status = Number(element.recStatus) === 1 ? true : false;
                        countrylist.push(element);
                    });
                    result.next(countrylist);
                }
            },
            (err) => {
                this.msgService.addErrorMsg({
                    summary: "Login",
                    detail: "Token Expired Please Login Again.",
                });
                setTimeout(() => {
                    localStorage.clear();
                    this.router.navigate(["/account/login"]);
                }, 1000);
            }
        );
        return result;
    }
    public getUOMCodes() {
        const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
        const data = new Subject<Array<ProductUOM>>();

        this.http.post(url, null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result) {
                    const result = response.result.UnitsOfMeasurement;
                    data.next(result);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
        return data;
    }
    public getDataRecords() {
        let resultList = new Array<Purchasesorders>();
        this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
            .subscribe((response: any) => {
                if (response && response.result && response.result.PurchasesordersList) {
                    resultList = response.result.PurchasesordersList.map(e => {

                        e.QuotDate = new Date(e.QuotDate);
                        e.OrdDate = new Date(e.OrdDate);
                        e.EnqDate = new Date(e.EnqDate);
                        e.pdfurl = this.config.ipAddress +"Purchasesorders/getPurchasesorderstList/"+e.purord_id;

                        return e;
                    });
                    this.dataList.next(resultList);
                }
            },
                err => {
                    this.msgService.addErrorMsg({ summary: 'Login', detail: 'Token Expired Please Login Again.' }); setTimeout(() => { localStorage.clear(); this.router.navigate(['/account/login']); }, 1000);
                });
    }

    public updateData(objData: Purchasesorders) {
        console.log(objData);
        objData.status = this.util.getSaveStatus();
        return this.Save(this.getUrl(ApiActions.Update), objData);
    }

    public saveDraft(objData: Purchasesorders) {
        objData.status = this.util.getDraftStatus();
        return this.Save(this.getUrl(ApiActions.DraftSave), objData);
    }

    private Save(action: string, objData: Purchasesorders) {
        const responseMsg = new Subject<string>();
        const fData = new FormData();

        fData.append("purord_id", objData.purord_id + "");
        fData.append("purquot_id", objData.purquot_id + "");
        fData.append("purenq_id", objData.purenq_id + "");
        fData.append("supp_id", objData.supp_id + "");
        fData.append('OrdCode', objData.OrdCode + '');

        fData.append('Ordcgst', objData.Ordcgst + '');
        fData.append('Ordsgst', objData.Ordsgst + '');
        fData.append('Ordigst', objData.Ordigst + '');
    
        fData.append('quoref', objData.quoref + '');
        fData.append('Ord_pterms', objData.Ord_pterms + '');

        fData.append('OrdDate', this.util.getDate(objData.OrdDate));

        const items = objData.items.map((e) => {
            return {
                purorddtl_id: e.purorddtl_id,
                prodId: e.item_id,
                prodSpecs: e.ItemSpecs,
                orderUom: e.uom_id,
                QuotQty: e.QuotQty,
                QuotPrice: e.QuotPrice,
                currency_id: e.currency_id,
                Deliverydate: this.util.getDate(e.DeliveryDate),
                OrdQty: e.OrdQty,
            };
        });

        fData.append('items', JSON.stringify(items));

        this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
            if (response) {
                this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
                responseMsg.next('');
            }
        },
            err => {
                responseMsg.next(err);
            });

        return responseMsg;
    }
}
